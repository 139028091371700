@import "~carbon-components/scss/globals/scss/vars";
@import "~carbon-components/scss/globals/scss/vendor/@carbon/layout/scss/breakpoint";

.#{$prefix}--header__nav {
  @include carbon--breakpoint("md") {
    display: block;
  }
}

.#{$prefix}--side-nav__header-navigation {
  display: none;
  @include carbon--breakpoint-down("md") {
    display: block;
  }
}

.#{$prefix}--side-nav {
  border-right: 1px solid gray;
}

// make content fill whole page,
// while leaving space for header and sidebar
.civnow-content-container {
  box-sizing: border-box;
  padding-top: 3rem;
  max-width: 100%;

  @include carbon--breakpoint("lg") {
    padding-left: 16rem;
  }
}
html,
body,
#root,
.civnow-content-container,
.civnow-content-scroll,
.civnow-fullheight {
  height: 100%;
}

.civnow-content-scroll {
  overflow: auto;
}

.civnow-content-maxwidth {
  max-width: 50rem;
  margin: auto;
  padding: 2rem;
}

img.civnow-fullwidth-image {
  max-width: 100%;
  margin: 0 auto;
  border: 1rem solid transparent;
  box-sizing: border-box;
}

.civnow-prose img {
  max-width: 100%;
}

p {
  margin: 1em 0;
}

h1,
h2 {
  margin-bottom: 1rem;
}

h3,
h4 {
  margin-bottom: 0.5rem;
}

div.civnow-clearfix {
  height: 0;
  clear: both;
}

.civnow-center-xy {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; // horizontal centering
}

.civnow-overlay-outer {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  position: relative;
}

.civnow-overlay-visible > .civnow-overlay-front {
  z-index: 8000;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 2rem;
  background-color: rgba(0, 0, 0, 0.5);
}
.civnow-overlay-visible > .civnow-overlay-back {
  filter: blur(6px);
  max-width: 100%;
  max-height: 100%;
}

.civnow-overlay-invisible > .civnow-overlay-front {
  display: none;
}
