@import "~carbon-components/scss/globals/scss/vendor/@carbon/layout/scss/breakpoint";

.civnow-playerinfo-avatar {
  float: right;
  margin: 0 0 1rem 1rem;
  width: 4rem;
  height: 8rem;
  min-width: 4rem;
  min-height: 8rem;

  @include carbon--breakpoint("lg") {
    width: 6rem;
    height: 12rem;
    min-width: 6rem;
    min-height: 12rem;
  }
}
