.civnow-player-card {
  width: 10rem;
  position: relative;
  float: left;
}

.civnow-player-card-avatar {
  width: 8rem;
  height: 8rem;
  min-width: 8rem;
  min-height: 8rem;
}

.civnow-player-card-info {
  padding-top: 0.5rem;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
